import React, {useState} from 'react'
import useForm from 'react-hook-form'

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }

export default () => {
	
	const [msg, setMsg] = useState('')
	
	const {register, handleSubmit, errors} = useForm()
		
	const onSubmit = (data, e) => {
						
		fetch("/", {
	        method: "POST",
	        headers: { "Content-Type": "application/x-www-form-urlencoded" },
	        body: encode({ "form-name": "Kontakt", ...data })
	      })
	        .then(() => {
				setMsg('Danke!')
				setTimeout(()=>{setMsg('')}, 4000)
				e.target.reset()
			})
			
	        .catch(error => setMsg(error));
	

	}

	return (
		<div>
			<div>Dem Lehmanski sein Newsletter erscheint dann und wann, aber manchmal ganz bestimmt einmal im Jahr oder öfter.<br/>Einfach Formular mit Mailadresse füttern und ab damit - und schon geht es nach einer kurzen Bestätigungsmail los ...<br/><br/></div>
			<form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off" data-netlify="true" netlify-honeypot="bot-field" name="Kontakt">
				<div className="row gtr-uniform">
					<div className="col-6 col-12-xsmall" >
						<input type="text" className={errors.name && 'error'} name="name" ref={register({required: true})} placeholder="Name*" />
					</div>
					<div className="col-6 col-12-xsmall">
						<input type="email" className={errors.email && 'error'} name="email" ref={register({required: true, pattern: {value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i}})} placeholder="E-Mail*" />
					</div>
					<div className="col-12">
						<textarea name="message" className={errors.message && 'error'} placeholder="Nachricht*" rows="6" ref={register({required: true})}></textarea>
					</div>
					<div className={'form-message' + (msg && ' completed')}>{msg}</div>
					<div className="col-12">
						<ul className="actions">
							<li><input type="submit" value="Absenden" className="primary" /></li>
							<li><input type="reset" value="Löschen" /></li>
						</ul>
					</div>
				</div>

			</form>
		</div>
	)
}