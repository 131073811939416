import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';

import ContactForm from '../components/ContactForm';
import Scroll from '../components/Scroll';

import SEO from "../components/seo"
import Img from "gatsby-image"

import { useInView } from 'react-intersection-observer'

		/*	{posts.map(post=>(
			<div key={post.id} style={{position: 'absolute', top: 0}}>
				<h2>{post.title}</h2>
				<div dangerouslySetInnerHTML={{ __html: post.content}}/>
				<hr></hr>
			</div>
		))
		}     */

const IndexPage = ({data}) => {	

	const [ref, homeInView] = useInView({
		threshold: 0.01
	})	
	
	let count = 0;
	
	const sections = data.wordpressWpApiMenusMenusItems.items.filter(item=>item.type === 'post_type')
	
	sections.forEach(e=>{
		e.style = 'style' + (count % 2 ? 4 : 2);
		e.content = (data.allWordpressPage.nodes.find(page=>page.title === e.title) || {}).content
		e.featured_media = (data.allWordpressPage.nodes.find(page=>page.title === e.title) || {}).featured_media
		count++;
	})
	
	const startPage = data.allWordpressPage.nodes.find(page=>page.path === '/') || {}
	const bg = startPage.featured_media && startPage.featured_media.localFile && 
		(startPage.featured_media.localFile.name + startPage.featured_media.localFile.ext)
	
	return (
  	<Layout background={bg} subsInView={!homeInView}>
	 <SEO
			    title={null}
			    description={null}
				image={bg && 'https://www.lehmanski.de' + startPage.featured_media.localFile.publicURL}
		    />
		{
			sections.map(page=> (
				<section id={'p' + page.object_id} key={page.object_id} className={"wrapper special " + page.style}>
					<div className="inner">
					{
						page.featured_media &&
						<div className="image">
							<Img fluid={page.featured_media.localFile.childImageSharp.fluid}/>
						</div>
					}
			        	<header className="major">
			          		<h2>{page.title}</h2>
							<div dangerouslySetInnerHTML={{ __html: page.content ? page.content.replace(/<p>\s<\/p>|###KONTAKT###/g, '') : ''}} />							
							{ 
								page.content && page.content.indexOf('###KONTAKT###') > -1 && <ContactForm/>
							}
			        	</header>	  						
			      	</div>
					 
			    </section>
      
	      ))}
		
  </Layout>
)};

export default IndexPage;
export const query = graphql`
  query {
    wordpressSiteMetadata {
     	name
		description      
    }
 	wordpressWpApiMenusMenusItems(name: {eq: "Hauptmenü"}) {
	    items {
	      	type
	      	title
			object_id
	    }
	  }
	  allWordpressPage {
	    nodes {
	      	title
			content
			path
	    }
	  }
	  logo: file(relativePath: {eq: "test.png"}) {
	    childImageSharp {
	      fluid(maxWidth: 1092) {
			...GatsbyImageSharpFluid
			src
	      }
	    }
	  }	
  }
`